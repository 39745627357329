@mixin bodyTypography() {
	font-size: 18px;
	line-height: 1.5em;
	font-weight: 500;
	font-family:$pFont;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}


@mixin bgImg($position, $size) {
	background: {
		position: $position;
		size:$size;
		repeat:no-repeat;
	}
}

@mixin overlay($color, $percentage) {
	position: relative;
	&:before {
		content:'';
		background-color:rgba($color, $percentage);
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}
}

@mixin gradientOverlay($deg, $color1, $color2) {
	background-blend-mode: multiply;
	background-image: linear-gradient(#{$deg}deg, $color1, $color2);
}

// list style mixin
@mixin list() {
  list-style-type:none;
  padding:0;
  margin:0;
}

@mixin headingAccent($color1, $color2, $position) {
	position:relative;
	padding-bottom:35px;
	&:before {
		content:'';
		width:100%;
		max-width:72px;
		height:2px;
		position:absolute;
		bottom:0;
		@if $position == center {
  			left:50%;
  			transform:translateX(-50%);
		} @else {
			left:0;
		}

		@if $color2 !=  '' {
			background-image: linear-gradient(180deg, $color1, $color2);
		} @else {
			background-color: $color1;
		}
	}
}