body {

	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;

	@include media-breakpoint-down(sm) {
		.sm-mb-2 {
			margin-bottom: 20px;
		}

		.sm-mb-4 {
			margin-bottom: 40px;
		}
	}

	@include media-breakpoint-down(md) {
		.md-mb-2 {
			margin-bottom: 20px;
		}

		.md-mb-4 {
			margin-bottom: 40px;
		}
	}

	/**
	 * Gutenberg styling Horizontal rule <hr>
	 */
	hr.wp-block-separator {
	    opacity: 0.3;
	}

	/**
	 * Make "invisible captcha" more invisible
	 */
	
	.grecaptcha-badge {
		opacity:0!important
	}

	&.has-notification-bar {
		main.main {
			margin-top: 129px;
		}
	}
	main.main {
		margin-top:$header-mobile-height;
	}
	
	.wrap {
		position: relative;

		img {
			max-width: 100%;
			height: auto;
		}
		p {
			a {
				color: $primary;
				transition: $transition;
				text-decoration: underline;
				&:hover {
					color: $secondary;
				}
			}
		}


		/* =============================================================================
		Extra Small +
		========================================================================== */
		@include media-breakpoint-up(xs) {
		}

		/* =============================================================================
		Small +
		========================================================================== */
		@include media-breakpoint-up(sm) {
		}

		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {




		}

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
			&.has-notification-bar {
				main.main {
					margin-top: 208px;
				}
			}
			main.main {
				margin-top:$header-desktop-height;
			}

		}

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		}
	}
}
