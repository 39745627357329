.block.home-banner {
	padding-top:0;
	overflow: hidden;
	padding-bottom:80px;
	.row {
		position: relative;
	}
	.image {
		&.desktop {
			@include bgImg(center, cover);
			width:111%;
			padding-bottom:75%;
			margin-left:-75px;
			display: none;
		}
		&.mobile {
			width: calc(100% + 30px);
			margin-left: -15px;
		}
	}

	.home-banner--title {
		color:$primary;
		margin:20px 0 0;
		font-size:45px;
		font-weight: 500;
	}

	.fadein-delay-home-banner-text {
		animation-delay: 0.35s;
	}

	.home-banner--intro-text-container {
		margin-top:30px;
		font-size:20px;
		.home-banner--intro-text {
			max-width: 720px;
			width:100%;
		}
	}

	.btn-wrap {
		margin-top:25px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.image {
			&.mobile {
				display: none;
			}
			&.desktop {
				display: block;
			}
		}

		.home-banner--title {
			font-size:60px;
			margin:0;
		}

		.home-banner--intro-text-container {
			margin-top:80px;
			font-size:27px;
			line-height: 1.35em;
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	

		.image {
			&.desktop {
				width:107%;
			}
		}

		.home-banner--title {
			font-size:90px;
		}


	}
}