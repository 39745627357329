.single {

	.single-post-content {
		overflow: hidden;
	}
	.banner {
		.image-wrap {
			position: relative;
			width: calc(100% + 30px);
		    margin-left: -15px;
		}
	}
	.banner-title {
		h1 {
			font-size:48px;
			color:$primary;
			font-weight: 500;
			margin-bottom:0;
		}
	}
	.single-banner-title-animation-delay {
		animation-delay:0.35s;
	}

	.post-content {
		.meta {
			margin-top:30px;
			margin-bottom: 15px;
			text-transform: uppercase;
			font-family: $roboto;
			color: $primary;
			font-size:16px;
			font-weight: bold;
			letter-spacing:1.5px;
			.date,
			.separator,
			.categories {
				display: inline-block;
			}
			.cat-item {
				&:after {
					content:', ';
				}
				&:last-child {
					&:after {
						content:'';
					}
				}
			}
		}

		.the-content {
			@include media-breakpoint-down(sm) {
				border-bottom:1px solid $grey-med;
				padding-bottom:20px;
				margin-bottom: 20px;
			}
			font-size:20px;
			p:first-of-type {
				color: $secondary;
				font-size: 24px;
				line-height: 1.4em;
			}
			line-height: 1.5em;
		}
	}

	/**
	 *  Gutenberg align classes
	 */
	.entry__content .alignfull,
	.entry__content .alignwide {
		margin-left: calc(-100vw / 2 + 100% / 2);
		margin-right: calc(-100vw / 2 + 100% / 2);
		max-width: 100vw;
	}

	@media only screen and (min-width: 950px) {
		.entry__content .alignwide {
			margin-left: -125px;
			margin-right: -125px;
			max-width: 950px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.banner {
			.image-wrap {
				margin-left: -136px;
				position: relative;
				width: calc(100% + 100px);
				max-width: none;
				.single-banner-title-animation-delay {
					animation-delay:0.35s;
				}
			}
		}


		.post-content {
			padding-right:50px;
			.meta {
				margin-top:30px;
			}
			.the-content {
				p:first-of-type {
					font-size: 27px;
				}
			}
		}

		.sidebar {
			margin-top:400px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {



	}
}
