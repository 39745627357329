
@font-face {
  font-family: "iconFont";
  src:  url('../fonts/iconFont.woff2?1685496467') format('woff2'),
        url('../fonts/iconFont.woff?1685496467') format('woff');
  font-weight: normal;
  font-style: normal;
}


@mixin iconFont {

  font-family: 'iconFont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}





[class^="icon-"], [class*=" icon-"] {
  
  &:before {@include iconFont;}

}



.icon-crew:before {
  content: "\"
}
.icon-linkedin:before {
  content: "\"
}
.icon-twitter:before {
  content: "\"
}
.icon-facebook:before {
  content: "\"
}
.icon-youtube:before {
  content: "\"
}
.icon-right-chevron-:before {
  content: "\"
}
.icon-down-chevron:before {
  content: "\"
}
.icon-back:before {
  content: "\"
}
.icon-next:before {
  content: "\"
}
.icon-bed:before {
  content: "\"
}
.icon-bathroom:before {
  content: "\"
}
.icon-den:before {
  content: "\"
}
.icon-art:before {
  content: "\"
}
.icon-restaurant:before {
  content: "\"
}
.icon-school:before {
  content: "\"
}
.icon-shopping:before {
  content: "\"
}
.icon-transportation:before {
  content: "\"
}
.icon-entertainment:before {
  content: "\"
}
.icon-plus:before {
  content: "\"
}
.icon-minus:before {
  content: "\"
}
.icon-close:before {
  content: "\"
}
.icon-instagram:before {
  content: "\"
}
.icon-big-instagram:before {
  content: "\"
}
.icon-search:before {
  content: "\"
}