.block.interior-banner {

	padding:0;
	overflow: hidden;
	@include media-breakpoint-down(sm) {
		margin:0!important;
	}
	.interior-banner--inner  {
		background-color:$primary;
		position:relative;
		padding:40px 0;
		.inner {
			padding-left: 15px;
			padding-right: 15px;
		}
		&:after {
			content:'';
			position: absolute;
			left:-100px;
			width:100px;
			height: 100%;
			background-color:$primary;
			top: 0;
		}
		&:before {
			content:'';
			position: absolute;
			left:0;
			width:100vw;
			height: 100%;
			background-color:$primary;
			top: 0;
		}
	}
	.interior-banner--inner-content {
		font-size:28px;
		line-height: 1.2em;
		position:relative;
		padding-left:15px;
		font-weight: 500;
		color:#fff;
		&:before {
			content:'';
			position: absolute;
			left:0;
			top:0;
			height: 100%;
			width:1px;
			background-color:white;
		}
	}

	.interior-banner-description--content {
		@include media-breakpoint-down(md) {
			padding-top:20px!important;
			padding-bottom: 20px!important;
		}
		font-size:20px;
		line-height: 1.4em;
		h1,h2,h3,h4,h5,h6 {
			color:$primary;
			margin:0;
			font-weight: 500!important;
		}
		&.stylize-description-text {
			color:$secondary;
			font-size:22px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.interior-banner-description--content {
			&.stylize-description-text {
				font-size:27px;
			}
		}

		.interior-banner--inner  {
			padding:110px 0;
		}
		.interior-banner--inner-content {
			font-size:38px;
			padding-left:30px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.interior-banner-description--content {
			h1 {
				font-size:60px;
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}