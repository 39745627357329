/**
 * Gutenberg editor fix
 */

 .editor-block-list__block.is-selected[aria-label^='Block: Image Content'] {
	z-index: 50;
}

.image-content-mobile-description {
	display: block;
	font-size:20px;
	padding:0px 0 30px;
	.btn-wrap {
		margin-top:20px;
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.block.image-content {
	position: relative;
	padding-bottom:0;
	.image-container {
		padding-bottom:56.25%;
		background: {
			size:cover;
			position:center;
		}
	}		

	@include media-breakpoint-down(sm) {
		.desktop-content {
			display: none;
		}
		&.small-padding-mobile {
			padding:0px 0;
			margin:0!important;
			.image-content--content {
				padding-bottom:0;
			}
			.image-container {
				display: none;
			}
		}
	}

	.image-content--title {
		font-size:44px;
		font-weight: 500;
		&.secondary {
			color:$secondary;
		}
		&.primary {
			color:$primary;
		}
		margin:0;
	}


	.fadein-delay-image-content-title {
		animation-delay: 0.35s;
	}
	.fadein-delay-image-content-description {
		animation-delay: 0.7s;
	}

	.image-content--content {
		padding:25px 0;
	}

	.image-content--description-link-container {
		&.desktop {
			display: none;
		}
	}

	.image-content--description {
		font-size:24px;
		h1,h2,h3,h4,h5,h6 {
			color:$primary;
			font-weight: 500;
			margin-bottom: 10px;
		}
		.wysiwyg {
			max-width:550px;
			&.text-color-primary {
				color:$primary;
			}
			&.text-color-secondary {
				color:$secondary;
				line-height: 1.4em;
			}
		}
	}

	.btn-wrap {
		margin-top:20px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-bottom:60px;

		.mobile-image-container-no-mobile-set {
			display: none;
		}

		.image-container {
			content:'';
			&.offset-text {
				width:34%;
				flex: none;
	    		max-width: none;
			}
			width:33.33%;
			height: 100%;
			position: absolute;
			bottom:0;
			left:0;
			padding-bottom:0;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}
			
		}

		.image-content--title {
			font-size:60px;
		}

		&.offset-image {
			.image-container {
				height: 125%;
				z-index: -1;
			}
		}
		.image-content--description {
			font-size:27px;
			line-height: 1.3em;
		}
		.image-content--description-link-container {
			&.desktop {
				display: block;
				margin-top:20px;
			}
			&.mobile {
				display: none;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.image-content--description {
			h1 {
				font-size:60px;
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
		.image-container {
			content:'';
			&.offset-text {
				width:40%;
			}
		}

		.image-content--description-link-container {
			padding-left:40px;
			&.desktop {
				margin-top:0px;
			}
		}

		.image-content--title {
			max-width: 90%;
		}

	}
}

@media screen and (min-width:781px) {
	.custom-spacing .desktop-content .image-content--content {
		margin-left:0px;
	}
}
@media screen and (min-width:1080px) {
	.custom-spacing .desktop-content .image-content--content {
		margin-left:0px;
	}
}
@media screen and (min-width:1700px) {
	.custom-spacing .desktop-content .image-content--content {
		margin-left:-3vw;
	}
}

