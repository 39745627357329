.menus.mobile {
	position: fixed;
	height: calc(100% - #{$header-mobile-height});
	width: 100%;
	z-index: 100;
	display: block;
	background-color: white;
	top: $header-mobile-height; // height of mobile header
	transform:translateX(100%);
	transition: $transition;
	overflow-y: auto;
	padding: 10px 0px 80px;
	.menu-open & {
		transform: translateX(0);
	}

	.admin-bar & {
		//top: calc(32px + #{$header-mobile-height});
	}

	nav {
		ul {
			@include list();
			li {
				a {
					display: block;
					text-decoration:none;
				}
			}
		}
		&.nav-primary {
			ul {
				li {
					padding:15px 10px;
					border-bottom:1px solid rgba($grey-med, 0.1);
					&.menu-item-has-children {
						position: relative;
						&:before {
							content:'';
							position: absolute;
							right: 15px;
							top: 27px;
							transform: translateY(-50%) rotate(0deg);
							@include iconFont;
							content: "\e007";
							color: black;
							font-size: 13px;
							transition: $transition;
							z-index: -1;
						}
						&.active-sub {
							&:before {
								margin-top: -4px;
								transform: rotate(-180deg);
								color:black;
							}
						}
					}
					.sub-menu {
						max-height: 0;
						overflow: hidden;
						transition: $transition;
						li {
							padding-top:5px;
							padding-bottom:5px;
							&:first-child {
								margin-top:15px;
							}
							border-bottom:none;
							a {
								color:black;
								font-size: 22px;
								padding-left: 15px;
							}
						}
					}
					a {
						color:black;
						font-size:28px;
						transition: $transition;
						font-weight: 500;
						letter-spacing: 0.050em;
						&:hover {
							color:black;
						}
					}
					&.social-icons {
						margin-top:40px;
						border-bottom:none;
						a {
							margin-right: 25px;
							display: inline-block;
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		display: none;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
