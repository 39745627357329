.post-item {

	.thumb-wrap {
		overflow: hidden;
	}
	.image-wrap {
		overflow: hidden;
		img {
			transition:$transition;
			display: block;
			max-width:none;
			width:100%;
		}
	}
	
	.post-title {
		margin-top:40px;
		font-size:22px;
		font-family:$roboto;
		font-weight: bold;
		margin-bottom:10px;
		a {
			text-decoration: none;
			color:$secondary;
			transition: $transition;
			&:hover {
				color:$primary;
			}
		}
	}

	.post-description {
		font-size:19px;
		line-height: 1.5em;
	}

	.btn-wrap {
		margin-top:10px;
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}