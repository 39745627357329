.menus.desktop {
	display: none;
	width: auto;
	ul.nav {
		list-style-type: none;
		padding: 0;
		text-align: right;
		margin: 0;
		padding-top:7px;
		li:not(.social-icons) {
			> a {
				&:hover {
					color:$primary;
				}
			}			
		}
		li.social-icons {
			vertical-align: -8px;
			a {
				padding:0;
				display: inline-block;
				margin-right:14px;
				&:last-child {
					margin-right: 0;
				}
				span {
					padding:4px;
					border:1px solid white;
					box-shadow:0 0 2px inset rgba(255,255,255,1);
					border-radius:5px;
					width:29px;
					height:29px;
					position:relative;
					display: block;
					transition:$transition;
					&:hover {
						background-color:$primary;
						border-color:$primary;
						color:black;
					}
					i {
						position:absolute;
						top:60%;
						left:55%;
						transform:translate(-50%,-50%);
						font-size:16px;
					}
				}

			}
		}
		& > li {
			display: inline-block;
			margin-right: 22px;
			position: relative;
			transition:padding 0.3s ease;
			.shrink & {
				padding:0;
			}
			&.menu-item-has-children {
				> a {
					&:after {
						@include iconFont();
						content: '\e007';
						font-size: 10px;
    					margin-left: 6px;
					}
				}
			}
			a {
				display: block;
				padding: 8px 0;
				text-decoration: none;
				color: $pColor;
				transition: $transition;
				font-weight: 300;
				letter-spacing:0.050em;
			}
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				ul {
					opacity: 1;
					visibility: visible;
					transition-delay: 0s, 0s;
					transform:translateY(0px);
				}
			}
			ul {
				top:100%;
				opacity: 0;
				visibility: hidden;
				z-index: 1;
				transition: opacity 0.2s ease 0s,visibility 0s ease .2s,transform 0.2s ease 0s;
				transform:translateY(-10px);
				background-color:white;
				@include list();
				position: absolute;
				left: -25px;
				text-align: left;
				padding:0px 0 15px;
				li {
					display: block;
					padding: 5px 25px;
					ul {
						display: none;
					}
					a {
						padding:0;
						color: $pColor;
						white-space: nowrap;
						transition: $transition;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		display: block;

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}