	@include bodyTypography();

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family:$hFont;
		line-height: 1em;
		font-weight: 600;
		color:$secondary;
	}

	p {
		
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom:0;
		}
		a {
			text-decoration: none;
			
			transition:$transition;
			&:hover {
				
			}
		}
	}

	strong {
		font-weight: 700;
	}
/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		h1 {

		}
		h2{
			&.heading-with-accent {
				font-size:50px;
			}
		}
		h3 {

		}
		h4 {
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}