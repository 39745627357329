// Colours
$red:#ef4238;
$blue: #21345f;
$light-blue: #306cfc;

$error: #d52d2d;

$grey-dark: #787878;
$grey-med: #cccccc;
$grey-light: #f7f7f7;

$black: #000000;
$white: #ffffff;

$primary: $red;
$secondary: $blue;

$pColor: $black;
$pColorAlt: $white;
$hColor: $grey-dark;

// Fonts
$crimson: 'Crimson Text', serif;
$roboto: 'Roboto', sans-serif;

$pFont: $crimson;
$fFont: $roboto;
$hFont: $crimson;

// Other
$transition: all 0.3s ease;

//$header-mobile-height: 80px;
//$header-desktop-height: 159px;

$header-mobile-height: 70px;
$header-desktop-height: 159px;
$sticky-main-menu-height: 73px;