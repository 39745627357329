.wrap .gform_wrapper {
	margin: 0;
	label.gfield_label {
		display: none;
		.gfield_required {
			color: $error;
		}
	}

	ul li.gfield {
		margin-top: 0;
		margin-bottom: 15px;
	}

	/**
	 *	Input, select and textarea styles
	 */

	input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
	select,
	textarea {
		background-color: transparent;
		color: rgba(255, 255, 255, 1);
		line-height: 2em;
		border:none;
		border-bottom:1px solid $white;
		// letter-spacing: 0.9px;
		// font-style: italic;
		font-size:20px;
		margin-bottom:0px;
		transition: $transition;
		font-family: $fFont;
		font-weight: 300;
		&:focus {
			outline: none;
			border-color: $primary;
		}
		&::placeholder {
			color: rgba(255, 255, 255, 1);
		}
	}

	input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
	select {
		// height: 25px;
		padding: 0px 0px;
	}

	button.btn {
		color: $white;
		font-size: 18px;
		&.underline {
			color: $white;
			border-bottom:1px solid $white;
			&:hover {
				border-bottom:1px solid $primary;
				color:$primary;
			}
		}
	}

	textarea {
		padding:10px 0 0;
	}

	.expand-on-focus {
		textarea {
			height: 50px;
		}
		&.expanded {
			textarea {
				height:50px;
			}
		}
	}

	/**
	 *	Submit Button
	 */

	.gform_footer {
		//text-align:center;
	}

	/**
	 *	Validation Styles
	 */

	div.validation_error {
		border: 0;
		background-color: $error;
		color: #fff;
		font-weight: 500;
		padding: 10px 10px;
		display: block;
		margin-top: 30px;
		margin-bottom: 0;
		line-height: 1.5em;
		display: none;
	}

	&.gform_validation_error .top_label li.gfield.gfield_error {
		// background-color: $error + 100;
		background-color: calc(#{$error} + 100);
		padding: 0px 10px;
		input[type="text"], input[type="email"], textarea {
			border: none;
			width:96%!important;
			margin-bottom:5px!important;
		}
		.ginput_container {
			padding-right: 10px;
		}
		.validation_message {
			color: #fff;
			font-weight: normal;
			line-height: 1.5em;
			padding-top: 6px;
			padding-bottom: 5px;
			font-size: 14px;
			text-align: left;
		}
	}

	li.gfield_error
		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
	li.gfield_error textarea {
		background-color: rgba(255, 255, 255, 0.8);
		border: none;
		border-bottom: 1px solid $primary;
		padding-left: 10px;
		padding-right: 10px;
	}

	&.gform_validation_error
		.gform_body
		ul
		li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
		border-bottom: 0;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

		.expand-on-focus {
			textarea {
				height: 75px;
			}
			&.expanded {
				textarea {
					height:75px;
				}
			}
		}

	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
