//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 600px,
        md: 782px,
        lg: 992px,
        xl: 1480px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 560px,
        md: 742px,
        lg: 960px,
        xl: 1440px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// END of Grid Options


// Bootstrap file imports
@import "breakpoints/mixins/breakpoints";
@import "grid/bootstrap-grid";
@import "sizing/utilities/sizing";
@import "layout/utilities/display";
@import "layout/utilities/spacing";
@import "layout/mixins/visibility";
@import "layout/utilities/visibility";