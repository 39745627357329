/**
 * Z-index fix for the editor
 */

div.editor-block-list__block[aria-label^='Block: Counter'] {
	z-index: 40;
}
.block.counter {
	padding:0;
	overflow: hidden;
	z-index: 10;
	.counter-container {
		background-color:#f8f8f8;
		padding:20px 0 40px;
		width: calc(100% + 30px);
		margin-left: -15px;
	}

	.counter--item {
		text-align: center;
		padding:10px 10px 15px;
		.counter--number {
			font-family: 'Georgia';
			font-weight: normal;
			color:$primary;
			font-size:90px;
			line-height: 1em;
		}
		.counter--title {
			margin-top:30px;
			font-family: $roboto;
			color:$secondary;
			text-transform: uppercase;
			font-size:16px;
			letter-spacing:1px;
			font-weight: bold;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.counter-container {
			padding:60px 0 75px;
			margin-left:0;
			width:100%;
		}

		.counter--item {
			padding:20px 20px 30px;
			border-right:1px solid $grey-med;
			&:last-child {
				border-right:none;
			}
			
			.counter--title {
				margin-top:15px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}