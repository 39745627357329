.block.quote {
	position:relative;
	padding:40px 0;
	@include media-breakpoint-down(sm) {
		margin-top:20px!important;
		margin-bottom:20px!important;
	}
	&:after {
		content:'';
		position: absolute;
		background-color:#f9f9f9;
		width:100%;
		height: 100%;
		right:0;
		top:0;
		z-index:-1;
	}
	.quote-content-container {
		.quote-content {
			color:$primary;
			font-size:27px;
			line-height: 1.3em;
		}
		.attribution-container {
			color:$secondary;
			font-family: $roboto;
			letter-spacing:1px;
			font-weight: bold;
			text-transform: uppercase;
			font-size:14px;
			margin-top:35px;
			line-height: 1.4em;
			.attribution {
			
			}
			.extra {
				font-style:italic;
				display: block;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding:80px 0;

		&:after {
			width: 78%;
		}

		.quote-content-container {
			.quote-content {
				font-size:36px;
			}
			.attribution-container {
				.extra {
					display: inline-block;
				}
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}