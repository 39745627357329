.menu-trigger {
	display: block;
	position: absolute;
	right: 20px;
	top: 50%;
	transform:translateY(-50%);
	width: 28px;
	height: auto;
	z-index: 4;
	transition: 0.3s;
	.bar {
		left: 0px;
		background-color: $secondary;
		height: 3px;
		display: block;
		transition: right 0.3s ease, transform 0.3s ease;
		transform: rotate(0deg);
		transform-origin: center center;
		border-radius: 5px;
		&.bar-1 {
			margin-bottom:6px;
		}
	}
	.menu-open & {
		.bar-2,
		.bar-1 {
			width: 24px;
			margin: -3px 0 0 0px;
		}
		.bar-2 {
			transform: rotate(45deg);
		}
		.bar-1 {
			margin-bottom: 0;
			transform: rotate(-45deg);
		}
	}
}