ul.social-icons {
	@include list();
	li {
		margin-top:12px;
		&:hover {
			span.icon {
				background-color:$primary;
				border-color:$primary;
				color:black;
			}
		}
		a {
			color:$grey-dark;
			text-decoration: none;
		}
		span {
			display: inline-block;
			vertical-align: -2px;
			&.icon {
				padding:4px;
				border:1px solid $grey-dark;
				border-radius:5px;
				width:23px;
				height:23px;
				position:relative;
				transition:$transition;
				vertical-align: middle;
				margin-right: 10px;
				color:$grey-dark;
			}
			i {
				position:absolute;
				top:60%;
				left:55%;
				transform:translate(-50%,-50%);
				font-size:14px;
			}
		}
	}
}