.block.post-list {
	padding-top:0;
	padding-bottom:40px;
	.article {
		margin-bottom:40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.post-list--title {
		text-align: left;
		margin-top:20px;
		font-size:48px;
		color:$secondary;
		position:relative;
		z-index:1;
		font-weight: 500;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.article {
			margin-bottom:0px;
		}
		.post-list--title {
			margin-top:18%;
			font-size:70px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.post-list--title {
			text-align: right;
		}
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}