.numeric-pagination {
	text-align: center;
	font-size: 15px;
	margin: 40px 0 0px;
	a {
		color: $grey-dark;
		text-decoration: none;

	}
	i {
		vertical-align: -3px;
	}

	.page-numbers {
		width: 30px;
		position: relative;
		display: inline-block;
		line-height: 30px;
		border-radius:50%;
		transition: $transition;
		&.current {
			background-color: $secondary;
			color:white;
		}
		&:hover {
			&:not(.current) {
				// background-color: $grey-light - 20;
				background-color: calc(#{$grey-light} - 20);
			}
		}
		&.next, &.prev {
			font-family: $pFont;
			width: auto;
			transition: $transition;
			display:inline-block;
			font-style: italic;
			.text {
				margin: 0 10px;
			}
			&:hover {
				background-color: transparent;
				color: $secondary;
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}

}