.footer-content {
	padding:20px 0 0;

	.border-top-container {
		position:relative;
		padding-top:50px;
		&:before {
			content:'';
			position: absolute;
			top:0;
			left:50%;
			width:calc(100% - 30px);
			transform:translateX(-50%);
			height: 1px;
			background-color:$grey-med;
		}
	}

	.main-footer {
		padding-bottom:60px;
		background-color: $blue;
		color: $pColorAlt;

		.footer-contact-details {
			font-family: $fFont;
			font-size: 20px;
			padding:40px 0 0;
			border-top: 1px solid $light-blue;
			a {
				text-decoration: none;
				color:$pColorAlt;
				&:hover {
					color:$primary;
				}
			}
			.footer-flex {
				align-items:first baseline;
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
			}
		}

		.footer-form-wrap {
			.form-description {
				font-size: 32px;
				font-weight: 300;
				line-height: 1.3em;
				margin-bottom:20px;
				padding-right: 20px;
			}
			.footer-form {
				.gform_footer {
					text-align: right;
				}
			}
			.footer-form {
				.gform_wrapper {
					position: relative;
				}
			}
		}

		// .main-footer-top {
		// 	padding-bottom: 40px;
		// }

		.main-footer-bottom {
			padding-top: 40px;
		}

	}


	textarea {
		padding-top: 0!important;
	}

	.socket {
		padding:15px 0;
		background-color:$blue;
	}

	.copyright-attribution {
		text-align: right;
	}
	.copyright {
		color:$pColorAlt;
		font-size:13px;
		display: inline-block;
	}
	.attribution {
		display: inline-block;
		text-decoration: none;
		white-space: nowrap;
		font-size:14px;
		color:$pColorAlt;
		span {

		}
		span, i {
			vertical-align: baseline;
			display: inline-block;
		}
		i {
		    vertical-align: -1px;
		    font-size: 13px;
		    margin-left: 1px;
		}
	}




	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding:90px 0 0;

		.attribution {
			margin-left: 40px;
		}

		.main-footer {
			.footer-contact-details {
				margin-top:0;
				// padding:80px 35px;
				// border-left: 1px solid $grey-med;
				border-top: 2px solid $light-blue;
				.footer-flex {
					gap: 60px;
				}
			}

			.footer-form-wrap {
				.footer-form {
					.gform_footer {
						position: absolute;
						right: 16px;
						bottom: 0;
						width: auto;
					}
				}
			}
		}


	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.logo-col {
			img {
				//max-width:90%;
			}
		}

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.main-footer {
			.footer-form-wrap {
				.form-description {
					font-size: 42px;
					margin-bottom:20px;
					padding-right: 60px;
				}
			}
		}

	}
}