.btn {
	display: inline-block;
	text-decoration: none;
	transition: $transition;
	text-align: center;
	position: relative;
	outline:none;
	border:none;
	appearance:none;
	text-transform: uppercase;
	font-weight: 700;
	cursor: pointer;
	letter-spacing:1px;
	font-size:14px;
	padding:14px 60px;
	font-weight: 700;
	font-family: $roboto;
	&.underline {
		line-height: 1em;
		color:black;
		background-color: transparent;
		position: relative;
		border-bottom:1px solid black;
		padding:7px 1px;		
		&:hover {
			border-bottom:1px solid $primary;
			color:$primary;
		}
	}
	&.underline-red {
		line-height: 1em;
		color:$primary;
		background-color: transparent;
		position: relative;
		border-bottom:1px solid $primary;
		padding:7px 1px;		
		&:hover {
			border-bottom:1px solid black;
			color:black;
		}
	}




	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}


