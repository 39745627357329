.site-header {
	background-color:white;
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100%;
	transition:transform 0.3s ease;
	.admin-bar & {
		top: 32px;
	}
	.site-info {
		transition: $transition;
		position:relative;
		z-index: 1;
		.row {
			position:relative;
			padding:0 15px;
			height: $header-mobile-height;
		}

		.brand {
			// width:calc(100% - 90px);
			width: 100%;
			max-width: 300px;
			margin-left: 15px;
			img {
				width:100%;
				display:block;
			}
		}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

		.site-info {
			.brand {
				margin-left: 0;
			}
		}

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {



	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.container-fluid {
			max-width:90%;
		}

		.menu-trigger {
			display: none;
		}

		.site-info {
			padding:0;
			.row {
				height: $header-desktop-height;
				transition:$transition;
				.shrink & {
					height:100px;
				}
			}
		}

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.site-info {
			.brand {
				width:100%;
				max-width: 349px;
				transition:$transition;
				.shrink & {
					max-width:300px;
					padding:10px;
				}
			}
		}

	}
}