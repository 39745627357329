.sidebar {
	animation-delay:0.7s;
	.widget {
		h3 {
			font-family: $roboto;
			text-transform: uppercase;
			font-size:16px;
			color:$secondary;
			font-weight: bold;
			letter-spacing: 1px;
		}
		ul {
			@include list();
			margin-bottom:40px;
			a {
				color:black;
				text-decoration: none;
				transition:$transition;
				&:hover {
					color:$primary;
				}
			}
		}
	}
	.archive-accordion-year {
		> a {
			position: relative;
			&:after {
				@include iconFont();
				content: "\";
				position: absolute;
				right:0;
				top:50%;
				transform: translateY(-50%);
				right: -23px;
				font-size: 13px;
			}
		}
		> ul {
			padding-left:15px;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	
		border-left:1px solid $grey-med;
		padding-left:30px;			
		

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}