.block.team-members {
	padding:0;
	
	.team-members--title-container {
		.title {
			position: relative;
			padding-top:35px;
			color:$secondary;
			&:before {
				content:'';
				position: absolute;
				top:0;
				left:0;
				width:52px;
				height: 2px;
				background-color:$secondary;
			}
		}
	}

	.team-members--list-container {
		.post-item {
			margin-bottom:70px;
			.image-wrap {
				width:100%;
				padding-bottom:73%;
				position: relative;
				display: block;
				.img {
					position: absolute;
					background: {
						position: center;
						size:cover;
						repeat:no-repeat;
					}
					width:100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
		}

		.team-member--title {
			color:$secondary;
			margin-bottom:11px;
			font-size:22px;
			font-weight: 800;
		}
		.team-member--secondary-position {
			font-style: italic;
			font-weight: 400;
		}
		.team-member--position {
			color:$secondary;
			font-style: italic;
			font-weight: 600;
			font-size:20px;
			margin-top: 5px;
			margin-bottom:10px;
		}

		.team-member--description {
			font-size: 18px;			
		}

	}
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.team-members--title-container {
			.title {
				font-size:50px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}