.wp-block-columns {

	&.full-width-single-column {
		& > .wp-block-column {
			width:100%;
			flex-basis:100%;
		}
	}

	&.no-bottom-margin {
		p {
			margin-bottom:10px!important;
		}
	}

	@include media-breakpoint-down(xs) {
		.wp-block-column {
			margin-bottom:0;
		}
	}
	&.has-1-columns {
		@include media-breakpoint-down(md) {
			.wp-block-column {
			    flex-basis: 100%;
			    flex-grow: 0;
			}
		}
	}
	&:not(.alignfull) {
		@extend .container;
	}
	
	&.margin-top-85 {
		margin-top:30px;
	}
	
	&.margin-bottom-85 {
		margin-bottom:0px;
	}

	&.margin-top-65 {
		margin-top:0px;
	}

	&.margin-bottom-65 {
		margin-bottom:0px;
	}

	h1,h2,h3,h4,h5,h6 {
		&.heading-with-accent {
			position: relative;
			padding-top:25px;
			color:$secondary;
			font-weight: 500;
			font-family: $pFont;
			&:before {
				content:'';
				position: absolute;
				top:0;
				left:0;
				width:52px;
				height: 2px;
				background-color:$secondary;
			}
		}
	}

	&.style-headings {
		p {
			margin-top:5px;
			margin-bottom:35px;
		}
		.wp-block-column {
			margin-bottom:0;
		}
		h1,h2,h3,h4,h5,h6 {
			&:not(.heading-with-accent) {
				margin-top:15px;
				margin-bottom:5px;
				color:$secondary;
				font-family: $roboto;
				font-weight: 600;
			}
		}
		h4 {
			&:not(.heading-with-accent) {
				margin-top:5px;
			}
		}
	}

	&.style-headings-red {
		h1,h2,h3,h4,h5,h6 {
			margin-top:10px;
			margin-bottom:0px;
			color:$primary;
			font-weight: 600;

		}
		p {
			margin-top:0;
		}
	}


	&.offset-third {
		.wp-block-column {
			&:last-child {
				display: none;
			}
		}
	}
	
	&.limit-column-width {
		p {
			margin-bottom:10px!important;
		}
		.wp-block-column {
			margin-bottom:20px!important;
		}
		
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

		&.offset-third,
		&.limit-column-width {
			.wp-block-column {
				margin-left: 0;
				margin-right: 0;
				width:50%;
				max-width:50%;
				flex-basis:50%;
				&:first-child {
					padding-right:15px;
				}
				&:nth-child(2) {
					padding-left:15px;
				}
			}
			
		}

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		&.margin-top-85 {
			margin-top:85px;
		}
		
		&.margin-bottom-85 {
			margin-bottom:85px;
		}

		&.margin-top-65 {
			margin-top:65px;
		}

		&.margin-bottom-65 {
			margin-bottom:65px;
		}

		&:not(.alignwide):not(.alignfull) {
			max-width: 950px;
		}

		&.offset-third {
			.wp-block-column:last-child {
				display: block;
				max-width:25%;
				width:25%;
			}
		}



	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		&.limit-column-width {
			.wp-block-column {
				max-width:33%;
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
		&.limit-column-width {
			.wp-block-column {
				max-width:25%;
			}
		}

	}
}